<template>
  <div class="section" id="contact">
    <div class="container is-max-desktop">
      <h1 class="title sectionTitle has-text-weight-bold" data-aos="fade-up">Skontaktuj się z nami!</h1>
      <div class="columns" style="padding-top: 35px">
        <div class="column is-half" data-aos="fade-up-right">
          <font-awesome-icon icon="fa-solid fa-envelope" class="primary" style="padding-bottom: 10px; font-size: 2.75rem" />
          <h1 class="title primary mailText tooltipp" id="contactText" @click="copyMail">
            contact@allmake.pl
            <span class="tooltipptext">Skopiowano do schowka!</span>
          </h1>
        </div>
        <div class="column is-half" data-aos="fade-up-left">
          <font-awesome-icon icon="fa-brands fa-discord" class="primary" style="padding-bottom: 15px; font-size: 2.75rem" />
          <br>
          <a class="discord-join" href="https://discord.com/invite/Ymd78XQW8U" target="_blank">Dołącz</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
  methods: {
    copyText(text) {
      const textArea = document.createElement("textarea");

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';


      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);
    },
    copyMail() {
      const el = document.getElementById("contactText");
      el.classList.add("active");
      this.copyText("contact@allmake.pl");

      setTimeout(() => el.classList.remove("active"), 2000);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.sectionTitle {
  font-size: 2.5rem;

  @include mobile {
    font-size: 2rem;
  }
}

.mailText {
  font-size: 2.25rem;

  @include mobile {
    font-size: clamp(1.5rem, 5vw, 2.25rem);
  }
}

.discord-join {
  border-radius: 32px;
  background-color: #0b9bf4;
  padding: 6px 65px;
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
  animation: pulse 5s ease infinite;
}

.discord-join:hover {
  color: #fff;
}

.tooltipp {
  position: relative;
  cursor: pointer;
}

.tooltipp .tooltipptext {
  margin-top: 5px;
  opacity: 0;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 1.1rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 5;
  bottom: 110%;
  left: 0;
  right: 0;


  margin-left: auto;
  margin-right: auto;

  transition: opacity .3s;

  @include mobile {
    font-size: 1rem;
    width: 220px;
  }
}


.tooltipp.active .tooltipptext {
  opacity: 1;
}
</style>
