<template>
  <div class="zaufaliContainer">
    <div class="zaufaliBox">
      <div class="containerCarousel">
        <div class="left-shadow"></div>
        <div style="transform: translate3d(0px, 0px, 0px);" id="carousel" class="carouselController">
          <div class="company" v-for="(company, id) in companies" :key="company" :id="'company' + id" :style="'order: ' + id">
            <img :src="company.img">
            <span>{{ company.name }}</span>
          </div>
        </div>
        <div class="right-shadow"></div>
      </div>
      <div class="zaufaliBox-control">
        <span class="iconLeft" @click="animateLeft(true)">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </span>
        <span class="iconRight" @click="animateRight(true)">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    companies: Array
  }, data() {
    return {
      position: 0,
      elements: [],
      userInteracted: false,
      animation: false,
      moveInterval: undefined
    }
  }, methods: {
     getEl() {
      if(window.screen.width < 600) return this.companies.length-1;
      if(window.screen.width < 750) return (this.companies.length-2);
      return this.companies.length-3;
    }, getSize() {
      return 160;
    }, animateRight(user) {
      if(user && !this.userInteracted) this.userInteracted = true;
      if(this.animation) return;

      this.animation = true;

      const element = document.getElementById("carousel");
      element.classList.remove("disableAnimation")

      element.style.transform = "translate3d(0px,0px, 0px)";

      const vue = this;
      setTimeout(function() {
        element.classList.add("disableAnimation")

        vue.changeOrderRight();

        element.style.transform = "translate3d(" + vue.getSize() + "px, 0px, 0px)";

        setTimeout(() => vue.animation = false, 750);
      }, 500);
    }, animateLeft(user) {
      if(user && !this.userInteracted) this.userInteracted = true;
      if(this.animation) return;

      this.animation = true;

      const element = document.getElementById("carousel");
      element.classList.remove("disableAnimation")

      element.style.transform = "translate3d(" + (this.getSize()*2) + "px,0px, 0px)";

      const vue = this;
      setTimeout(function() {
        element.classList.add("disableAnimation")

        vue.changeOrderLeft();

        element.style.transform = "translate3d(" + vue.getSize() + "px, 0px, 0px)";

        setTimeout(() => vue.animation = false, 750);
      }, 500);
    }, changeOrderRight() {
      const vue = this;

      this.elements.forEach(el => {
        if(parseInt(el.style.order) === 0) el.style.order = vue.companies.length-1;
        else el.style.order = parseInt(el.style.order)-1;
      });
    }, changeOrderLeft() {
      const vue = this;

      this.elements.forEach(el => {
        if(parseInt(el.style.order) === vue.companies.length-1) el.style.order = 0;
        else el.style.order = parseInt(el.style.order)+1;
      });
    }, createMoveInterval() {
      this.userInteracted = false;

      const vue = this;
      this.moveInterval = setInterval(function() { vue.animateRight(false) }, 5000);
    }
  }, mounted() {
    for (let x = 0; x < this.companies.length; x++)
      this.elements.push(document.getElementById("company" + x));

    const element = document.getElementById("carousel");
    element.style.transform = "translate3d(" + this.getSize() + "px, 0px, 0px)";

    this.createMoveInterval();
  }, watch: {
    userInteracted() {
      clearInterval(this.moveInterval);

      const vue = this;
      setTimeout(() => vue.createMoveInterval(), 5000)
    }
  }
}
</script>

<style>
.zaufaliContainer {
  overflow-x: auto;
  min-width: 800px;
  max-width: 800px;
}

.containerCarousel {
  width: 640px;
  position: relative;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 900px) {
  .zaufaliContainer {
    overflow-x: auto;
    min-width: 500px;
    max-width: 500px;
  }

  .containerCarousel {
    width: 320px;
  }
}

@media screen and (max-width: 600px) {
  .zaufaliContainer {
    overflow-x: auto;
    min-width: 350px;
    max-width: 350px;
  }

  .containerCarousel {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .zaufaliContainer {
    overflow-x: auto;
    min-width: 290px;
    max-width: 290px;
  }

  .containerCarousel {
    width: 160px;
  }
}


.zaufaliBox-control {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
}


.zaufaliBox {
  position: relative;
  padding: 15px;
  border-radius: 45px;
  border: rgba(0,0,0,0.6) solid 1px;
  background-color: #fff;

  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.carouselController {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  align-items: center;
  justify-content: right;
  -webkit-justify-content: flex-end;
  transition: transform .3s;
  margin-right: 20px;
}

.containerCarousel::-webkit-scrollbar {
  display: none;
}

.zaufaliBox::-webkit-scrollbar {
  display: none;
}

.company {
  display: flex;
  flex-direction: column;
  gap: 15px
}

.company > span {
  font-weight: 500;
  font-family: Poppins,sans-serif;
}

.company > img {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 50%;
}

.iconLeft {
  position:absolute;
  font-size: 3rem;
  left: 16px;
  cursor: pointer;
}

.iconRight {
  position:absolute;
  font-size: 3rem;
  right: 16px;
  cursor: pointer;
}

.left-shadow {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 4;
  background: linear-gradient(90deg, rgba(255,255,255,0.7) 10%, rgba(255,255,255,0) 100%);
  /*background-color: #000;*/
}

.right-shadow {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 4;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 90%);
  right: 0;
}

.disableAnimation {
  transition: none !important;
}
</style>
