<template>
  <div :class="['loaderr', {'leave': !active}]">
    <img src="/loading.gif" class="eye">
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loaded: Boolean
  }, data() {
    return {
      active: true
    }
  }, watch: {
    loaded: {
      immediate: true,
      deep: true,
      handler(value) {
        if(!value) return;
        const vue = this;

        setTimeout(() => {
          vue.active = false;
          document.body.classList.remove("disable-scrollbar");
        }, 1500);
      }
    }
  }
}
</script>

<style scoped>
.loaderr {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #1A1A1A;

  z-index: 420;

  display: flex;
  justify-content: center;
  align-items: center;
}

.leave {
  animation: leave .5s;
  animation-fill-mode: forwards;
}

@keyframes leave {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    z-index: -1000;
    display: none;
  }
}

.eye {
  height: 120px;
  /*animation: anim 1s infinite;*/
  mask-image: url(/images/oko.svg);
  mask-repeat: no-repeat;
  background-color: white;

  mask-position: 0% 50%;
}


@-webkit-keyframes anim {
  0% { filter: brightness(0) invert(1); }
  50% { filter: invert(58%) sepia(44%) saturate(6267%) hue-rotate(180deg) brightness(102%) contrast(102%); }
  100% { filter: brightness(0) invert(1); }
}

</style>
