export function checkForHashTag() {
  if(window.location.hash) {
    const hash = window.location.hash.replace("#", "");

    const el = document.getElementById(hash);

    if (el) {
      const rect = el.getBoundingClientRect();
      window.scrollTo(0, rect.top);
    }
  }
}

const ids = {
  "/" : ["about-us", "trustUs", "contact"],
  "/portfolio" : ["contact"],
  "/reklama" : ["contact"],
}

function isOnThisWebsite(id, path) {
  if(ids[path].includes(id)) return true;
  return false;
}

export function scrollTo(id, router, path) {
  if(isOnThisWebsite(id, path)) {
    const rect = document.getElementById(id).getBoundingClientRect();

    window.scroll(0, rect.y);
  } else {
    router.push("/#" + id)
  }
}
