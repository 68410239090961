<template>
  <div :class="['cookieCard', {'active': active}]">
    <div class="is-relative">
      <font-awesome-icon icon="fa-solid fa-xmark" class="xmark" @click="decline"></font-awesome-icon>
      <img src="/images/icons/cookie.svg" style="height: 40px;">
      <br>
      <span class="tit">Korzystamy z <span class="cookieColor">COOKIES</span></span>
      <br>
      <span class="desc">Dzięki temu wiemy m.in. skąd się u nas znalazłeś!<br><a class="link" href="https://policies.google.com/privacy" target="_blank">więcej informacji tutaj</a></span>
      <br>
      <a class="button-accept" @click="accept">Zgadzam się</a>
    </div>
  </div>
</template>

<script>
import {getCookie, setCookie} from "@/cookies";

export default {
  name: "CookiePopup",
  created() {
    if(!getCookie("accept") && !getCookie("declined")) {
      this.active = true;
    } else {
      if(getCookie("accept")) this.addGoogleAnalytics()
    }
  }, data() {
    return {
      active: false
    }
  }, methods: {
    accept() {
      setCookie("accept", "true", 365);
      this.active = false;
      this.addGoogleAnalytics();
    }, decline() {
      setCookie("declined", "true", 365);
      this.active = false;
    }, addGoogleAnalytics() {
      const gtag = document.createElement("script");
      gtag.async = true;
      gtag.src = "https://www.googletagmanager.com/gtag/js?id=G-61MPL523GG";

      const gtag2 = document.createElement("script");
      gtag2.type="text/javascript";
      gtag2.innerHTML = 'window.dataLayer = window.dataLayer || [];\n' +
                       'function gtag(){dataLayer.push(arguments);}\n' +
                       'gtag(\'js\', new Date());\n' +
                       'gtag(\'config\', \'G-61MPL523GG\')';


      const head = (document.head || document.getElementsByTagName('head')[0]);
      head.appendChild(gtag);
      head.appendChild(gtag2);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.cookieCard {
  position: fixed;
  left:0;
  right: 0;
  z-index: 10;
  margin-left: 5vw;
  width: 380px;
  bottom: 0;

  background-color: #fff;
  padding: 20px;
  border-radius: 64px 64px 0 0;

  transition: transform .5s;

  transform: translateY(105%);

  box-shadow: 0px 0px 47px -30px rgba(66, 68, 90, 1);

  @include touch {
    margin-left: auto;
    margin-right: auto;
  }

  @include mobile {
    width: 320px;
  }
}

.cookieCard.active {
  transform: translateY(0%);
}

.cookieColor {
  color: #d79448;
}

.tit {
  font-size: 1.6rem;
  color: #191919;
  font-weight: 800;

  @include mobile {
    font-size: 1.45rem;
  }
}

.desc {
  font-size: 0.95rem;
  color: #191919;
  font-weight: 600;

  @include mobile {
    font-size: 0.95rem;
  }
}

.button-accept {
  margin-top: 10px;
  filter: drop-shadow(0px 2px 0px #764a18);
  background-color: #d79347d9;
  color: #fbfbfb;
  font-weight: 600;
  border-radius: 64px;
  font-size: 1.4rem;
  padding: 5px 15px;
  display: inline-block;
}

.link {
  font-size: 0.95rem;
  text-decoration: underline;
  color: #d79448;
  font-weight: 600;

  @include mobile {
    font-size: 0.95rem;
  }
}

.xmark {
  color: #bebebe;
  position: absolute;
  top:1px;
  right:15px;
  font-size: 2rem;

  transition: color .3s;
  cursor: pointer;
}

.xmark:hover {
  color: #d79347d9;
}
</style>
