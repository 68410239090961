<template>
  <div>
    <div :class="[{blurBg: true}, {show: active}]"></div>

<!--    :class="[{mobileBox: true}, {active: active}, {offBox: active}]"-->
    <div :class="[{mobileBox: true}, {active: active}, {offBox: active}]" @click="active = false">
      <div class="mobileBoxCard">
        <div style="position: absolute; display: flex; justify-content: center; top: -25px; left:0; right:0; width: 100%;">
          <div style="padding: 8px 20px; background-color: #0da1ff; border-radius: 16px; box-shadow: 0px 0px 25px 0px rgba(13, 161, 255, 1); text-shadow: 0px 3px 0px rgba(0,0,0,0.21);">
            <span :style="'font-size:' + item.fontSize + 'px; font-weight: 500; color: white;'">{{ item.title }}</span>
          </div>
        </div>
        <h1 class="subtitle is-6 has-text-weight-semibold mt-0 has-text-black mt-5" style="font-size: 1.1rem;">{{ item.subtitle }}</h1>
      </div>
    </div>
    <div class="is-flex" style="flex-flow: row wrap;">
      <div style="position: relative; flex:1;">
        <div style="position: absolute;" class="t-top t-left t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem reklam</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Stworzymy dla ciebie reklamę<br>twojego projektu!</h1>
          </div>
        </div>
        <div style="position: absolute;" class="t-middle t-left t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Lektorat</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Nagramy świetny lektorat pod twój film!</h1>
          </div>
        </div>
        <div style="position: absolute;" class="t-bottom t-left t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Montażem Wideo</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Zmontujemy film wedle twoich oczekiwań!</h1>
          </div>
        </div>
<!--        <div class="about-left top">-->
<!--          <div style="margin-left: auto; position:relative; width: 50%">-->
<!--            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem reklam</h1>-->
<!--            <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy dla ciebie reklamę<br>twojego projektu!</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="about-left top">-->
<!--          <div style="margin-left: auto; position:relative; width: 50%">-->
<!--            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem reklam</h1>-->
<!--            <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy dla ciebie reklamę<br>twojego projektu!</h1>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="eyeContainer" style="position: relative; margin-right: auto; margin-left: auto">
        <img src="/images/eye/oko.svg" class="eye">
        <div class="about-icon top right" @click='open( "BOTami Discord","Napiszemy dla ciebie bota przeznaczonego konkretnie pod twój serwer!", 24)'>
          <font-awesome-icon icon="fa-brands fa-discord"></font-awesome-icon>
        </div>

        <div class="about-icon top left" @click='open( "Tworzeniem reklam","Stworzymy dla ciebie reklamę twojego projektu!", 24)'>
          <font-awesome-icon icon="fa-solid fa-rectangle-ad"></font-awesome-icon>
        </div>

        <div class="about-icon middle left" @click='open("Lektorat","Nagramy świetny lektorat pod twój film!", 24)'>
            <font-awesome-icon icon="fa-solid fa-microphone-lines"></font-awesome-icon>
        </div>

        <div class="about-icon middle right" @click='open("Tworzeniem Grafiki Cyfrowej","Stworzymy szatę graficzną dla twojego projektu!", 18)'>
          <font-awesome-icon icon="fa-solid fa-palette"></font-awesome-icon>
        </div>

        <div class="about-icon bottom right" @click='open("Projektowaniem Stron WWW","Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!", 18)'>
          <font-awesome-icon icon="fa-solid fa-globe"></font-awesome-icon>
        </div>

        <div class="about-icon bottom left" @click='open("Montażem Wideo","Zmontujemy film wedle twoich oczekiwań!", 24)'>
          <font-awesome-icon icon="fa-solid fa-clapperboard"></font-awesome-icon>
        </div>
      </div>
      <div style="position: relative; flex: 1">
        <div style="position: absolute;" class="t-top t-right t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">BOTami Discord</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Napiszemy dla ciebie bota<br>przeznaczonego konkretnie pod twój serwer!</h1>
          </div>
        </div>
        <div style="position: absolute;" class="t-middle t-right t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem Grafiki Cyfrowej</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Stworzymy szatę graficzną dla twojego projektu!</h1>
          </div>
        </div>
        <div style="position: absolute;" class="t-bottom t-right t-text">
          <div style="margin-left: auto; position:relative;">
            <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Projektowaniem Stron WWW</h1>
            <h1 class="subtitle has-text-weight-semibold mt-0 is-6" style="text-align: left">Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!</h1>
          </div>
        </div>
      </div>
    </div>
    <span class="subtitle text-info has-text-weight-semibold has-text-centered ml-1 mr-1">Kliknij na ikonki aby <span class="primary">dowiedzieć się więcej</span></span>
<!--    <div class="is-flex is-justify-content-center is-align-items-center" style="flex-flow: row wrap;">-->
<!--      <div class="about-left top">-->
<!--        <div style="margin-left: auto; position:relative; width: 50%">-->
<!--          <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem reklam</h1>-->
<!--          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy dla ciebie reklamę<br>twojego projektu!</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="position: relative;">-->
<!--        <img src="/images/eye/oko1.png"  class="about-image small-img">-->
<!--        <div class="about-icon small-left-top">-->
<!--          <font-awesome-icon icon="fa-solid fa-rectangle-ad"-->
<!--                             @click='open("fa-solid fa-rectangle-ad", "Tworzeniem reklam","Stworzymy dla ciebie reklamę twojego projektu!")'>-->
<!--          </font-awesome-icon>-->
<!--        </div>-->
<!--        <div class="about-icon small-right-top">-->
<!--          <font-awesome-icon icon="fa-brands fa-discord"-->
<!--                             @click='open("fa-brands fa-discord", "BOTami Discord","Napiszemy dla ciebie bota przeznaczonego konkretnie pod twój serwer!")'></font-awesome-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="about-right top">-->
<!--        <h1 class="title has-text-weight-bold mb-0">BOTami Discord</h1>-->
<!--        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Napiszemy dla ciebie bota przeznaczonego konkretnie<br> pod twój serwer!</h1>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="is-flex is-justify-content-center is-align-items-center">-->
<!--      <div class="about-left">-->
<!--        <div style="margin-left: auto; position:relative; width: 50%">-->
<!--          <h1 class="title has-text-weight-bold mb-0">Lektorat</h1>-->
<!--          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Nagramy świetny lektorat pod twój film!</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="position: relative;">-->
<!--        <img src="/images/eye/oko2.png" class="about-image big-img">-->
<!--        <div class="about-icon big-left">-->
<!--          <font-awesome-icon icon="fa-solid fa-microphone-lines"-->
<!--                             @click='open("fa-solid fa-microphone-lines", "Lektorat","Nagramy świetny lektorat pod twój film!")'></font-awesome-icon>-->
<!--        </div>-->
<!--        <div class="about-icon big-right">-->
<!--          <font-awesome-icon icon="fa-solid fa-palette"-->
<!--                             @click='open("fa-solid fa-palette", "Tworzeniem Grafiki Cyfrowej","Stworzymy szatę graficzną dla twojego projektu!")'></font-awesome-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="about-right">-->
<!--        <h1 class="title has-text-weight-bold mb-0">Tworzeniem Grafiki Cyfrowej</h1>-->
<!--        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy szatę graficzną dla twojego projektu!</h1>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="is-flex is-justify-content-center is-align-items-center" style="margin-left: auto">-->
<!--      <div class="about-left bottom" style="transform: translateY(40%)">-->
<!--        <div style="margin-left: auto; position:relative; width: 50%">-->
<!--          <h1 class="title has-text-weight-bold mb-0">Montażem Wideo</h1>-->
<!--          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Zmontujemy film wedle twoich oczekiwań!</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div style="position: relative;">-->
<!--          <img src="/images/eye/oko3.png" class="about-image small-img">-->
<!--          <div class="about-icon small-left-bottom">-->
<!--            <font-awesome-icon icon="fa-solid fa-clapperboard"-->
<!--                               @click='open("fa-solid fa-clapperboard", "Montażem Wideo","Zmontujemy film wedle twoich oczekiwań!")'></font-awesome-icon>-->
<!--          </div>-->
<!--          <div class="about-icon small-right-bottom">-->
<!--            <font-awesome-icon icon="fa-solid fa-globe"-->
<!--                               @click='open("fa-solid fa-globe", "Projektowaniem Stron WWW","Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!")'></font-awesome-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="about-right" style="transform: translateY(40%)">-->
<!--        <h1 class="title has-text-weight-bold mb-0">Projektowaniem Stron WWW</h1>-->
<!--        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!</h1>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Eye",
  data() {
    return {
      active: false,
      item: {
        title: "Tworzeniem reklam",
        subtitle: "Stworzymy dla ciebie reklamę twojego projektu!",
        fontSize: 18
      }
    }
  }, methods: {
    open( title, subtitle, fontSize ) {
      if(window.screen.width > 1400) return;
      this.item = {title, subtitle, fontSize };
      this.active = true;
    },  preventScroll(e){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }, watch: {
    active(value) {
      if (value) {
        document.addEventListener('touchmove', this.preventScroll, { passive:false });
      } else {
        document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      }
    }
  }
}
</script>

<style scoped>
/* DESKTOP */
.eye {
  width: 500px;
  margin: 50px;
}

.eyeContainer {
  position: relative;
}

.about-icon {
  position:absolute;
  border-radius: 50%;
  background-color: #0da1ff;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: pulse 5s ease infinite;

  width: 64px;
  height: 64px;
}

.about-icon > * {
  font-size: 2.25rem;
  color: white;
}

.t-top {
  top: 20px;
}

.t-bottom {
  bottom: 20px;
}

.t-middle {
  top: 195px;
}

.t-middle.t-right {
  left: 30px;
}

.t-middle.t-left {
  right: 30px;
}


.t-top.t-left, .t-bottom.t-left {
  right: -40px
}

.t-top.t-right, .t-bottom.t-right {
  left: -40px
}

.top {
  top: 15px;
}

.bottom {
  bottom: 25px;
}

.top.right, .bottom.right {
  right: 120px;
}

.top.left, .bottom.left {
  left: 120px;
}

.middle {
  top: 180px;
}

.middle.left {
  left: 20px;
}

.middle.right {
  right: 20px;
}

.text-info {
  display: none;
}

@media screen and (max-width: 1400px) {
  .t-text {
    display: none;
  }

  .text-info {
    display: block;
    font-size: 1.2rem;
  }

}

/* TABLET */
@media screen and (max-width: 600px) {
  .eye {
    width: 400px;
  }

  .about-icon {
    width: 58px;
    height: 58px;
  }

  .top {
    top: 25px;
  }

  .bottom {
    bottom: 25px;
  }

  .top.right, .bottom.right {
    right: 110px;
  }

  .top.left, .bottom.left {
    left: 110px;
  }

  .middle {
    top: 150px;
  }

  .middle.left {
    left: 20px;
  }

  .middle.right {
    right: 20px;
  }
}

/* PHONE */
@media screen and (max-width: 550px) {
  .eye {
    width: 300px;
    margin: 42px;
  }


  .about-icon {
    width: 48px;
    height: 48px;
  }

  .about-icon > * {
    font-size: 1.75rem;
    color: white;
  }

  .top {
    top: 25px;
  }

  .bottom {
    bottom: 25px;
  }

  .top.right, .bottom.right {
    right: 80px;
  }

  .top.left, .bottom.left {
    left: 80px;
  }

  .middle {
    top: 115px;
  }

  .middle.left {
    left: 20px;
  }

  .middle.right {
    right: 20px;
  }
}

@media screen and (max-width: 400px) {
  .eye {
    width: 250px;
    margin: 25px;
  }


  .about-icon {
    width: 42px;
    height: 42px;
  }

  .about-icon > * {
    font-size: 1.5rem;
    color: white;
  }

  .top {
    top: 5px;
  }

  .bottom {
    bottom: 5px;
  }

  .top.right, .bottom.right {
    right: 60px;
  }

  .top.left, .bottom.left {
    left: 60px;
  }

  .middle {
    top: 85px;
  }

  .middle.left {
    left: 5px;
  }

  .middle.right {
    right: 5px;
  }
}


/*@media screen and (max-width: 1500px) {*/
/*  .about-right, .about-left {*/
/*    display: none;*/
/*  }*/
/*}*/

/*.about-icon-text {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*}*/

/*.about-image {*/
/*  max-width: 100%;*/
/*  max-height: 100%;*/
/*}*/


/*.small-left-top {*/
/*  top:5%;*/
/*  left:7%;*/
/*}*/

/*.small-right-top {*/
/*  top:5%;*/
/*  right:7%;*/
/*}*/

/*.small-left-bottom {*/
/*  top:38%;*/
/*  left:7%;*/
/*}*/

/*.small-right-bottom {*/
/*  top:38%;*/
/*  right:7%;*/
/*}*/

/*.big-left {*/
/*  top:23%;*/
/*  left:4%;*/
/*}*/

/*.big-right {*/
/*  top:23%;*/
/*  right:4%;*/
/*}*/

/*.small-img {*/
/*  width: calc(344px + 16px);*/
/*  height: calc(114px + 16px);*/
/*}*/

/*.big-img {*/
/*  width: calc(544px + 16px);*/
/*  height: calc(114px + 16px);*/
/*}*/

/*@media screen and (max-width: 700px) {*/
/*  .small-img {*/
/*    !*transform: scale(0.6);*!*/
/*    width: 210px;*/
/*    height: 72px;*/
/*  }*/

/*  .big-img {*/
/*    width: 340px;*/
/*    height: 72px;*/
/*  }*/

/*  .about-icon {*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    cursor: pointer;*/
/*  }*/

/*  .about-icon > * {*/
/*    font-size: 1.75rem;*/
/*  }*/
/*}*/

/*.about-left {*/
/*  flex: 1;*/
/*  text-align: left;*/
/*}*/

/*.about-right {*/
/*  flex: 1;*/
/*  text-align: left*/
/*}*/

/*.top {*/
/*  transform: translateY(-20%);*/
/*}*/

/*.about-left.bottom {*/
/*  transform: translateY(30%);*/
/*}*/

.mobileBox {
  position: fixed;

  z-index: -1002;

  top: 0;
  left: 0;
  right:0;
  bottom:0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity .5s;
}

.mobileBox.off {
  transition: off-box .5s;
}

.mobileBoxCard {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  /*background-image: url('/images/decoration/xd.png');*/
  /*background-size: cover;*/
  padding: 10px;
  position: absolute;
  filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.57));
  border-radius: 10px;
}

.mobileBoxX {
  position: absolute;
  right: 15px;
  top: 5px;
  font-weight: 800;
  font-size: 1.25rem;
  cursor: pointer;
}

.mobileBox.active {
  z-index: 1002;
  opacity: 1;
}

.blurBg {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  z-index: -1000;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/

  /* blur(0.00000001px) */
  backdrop-filter: opacity(0);

  transition: backdrop-filter .5s, z-index .5s step-end;
}

.blurBg.show {
  transition: backdrop-filter .5s, z-index .5s step-start;

  z-index: 1000;
  backdrop-filter: blur(5px) opacity(1);
}


@keyframes off-box {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    z-index: -1000;
  }
}
</style>
