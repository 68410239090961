<template>
  <div class="container mobile-padding-nav margin-nav" style=" position: relative">
<!--    <div :class="[{blurBg: true}, {show: active}]"></div>-->
    <div class="nav">
      <div class="logo" @click="$router.push('/')">
        <img :src="logo" />
      </div>
      <div :class="['nav-items', {'nav-active': active}]">
        <div style="display: flex; justify-content: center">
          <img src="/images/decoration/misiu.svg" class="misiuNaLinieAleNieSamobojca">
        </div>
        <div>
          <ul>
            <li class="nav-text" @click="changeWebsite('/')"><a>Strona Główna</a></li>
            <li class="nav-text" @click="scrollTo('about-us')"><a>Czym się zajmujemy</a></li>
            <li class="nav-text" @click="scrollTo('trustUs')"><a>Zaufali nam</a></li>
            <li class="nav-text" @click="changeWebsite('/portfolio')"><a>Portfolio</a></li>
            <li><a class="nav-button" @click="scrollTo('contact')">Kontakt</a></li>
          </ul>
        </div>
      </div>
      <div class="break"></div>
      <div :class="['lines', {'active': active}]" @click="active = !active">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {scrollTo} from "@/scrollUtil";

export default {
  name: "Navbar",
  data() {
    return {
        active: false,
        logo: "/images/logo.png"
    }
  }, watch: {
    active(value) {
      const body = document.body;
      if(value) {
        document.addEventListener('touchmove', this.preventScroll, { passive:false });

        body.classList.add('disable-scrollbar')
      } else {
        document.removeEventListener('touchmove', this.preventScroll, { passive: false });
        body.classList.remove('disable-scrollbar')
      }

      this.$emit("blur", value)
      const vue = this;

      if(value) setTimeout(() => vue.logo = '/images/eszkere.png', 450);
      else this.logo = "/images/logo.png"
    }
  }, methods: {
    scrollTo(id) {
      document.body.classList.remove('disable-scrollbar');
      document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      this.active = false;

      const vue = this;
      setTimeout(() => scrollTo(id, vue.$router, vue.$route.path), 500);
    }, preventScroll(e){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }, changeWebsite(path) {
      document.body.classList.remove('disable-scrollbar');
      document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      this.active = false;

      const vue = this;
      setTimeout(() => vue.$router.push(path), 500);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.margin-nav {
  margin-top: 3.75rem;

  @include touch() {
    margin-top: 2.5rem;
  }
}

.misiuNaLinieAleNieSamobojca {
  transform: scale(1);
  top: 55vh;
  position: absolute;

  @include from(1023px) {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: row wrap;
}

.nav-text {
  margin-left: 35px;
  margin-right: 35px;
}

.nav-text > a {
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.5s;
}

.nav-text > a:hover {
  color: #08adf7;
}

.nav-button {
  padding: 4px 20px;

  background-color: #000;
  color: #fff;
  border-radius: 32px;
  font-weight: 700;
  font-size: 1rem;
  transition: background-color 0.5s;

  margin-left: 25px;

  @include until(1250px) {
    margin-left: 20px;
  }

  //@include until(700px) {
  //  margin-left: 30px;
  //}

  @include touch {
    margin-left: 0;
  }
}

.nav-button:hover {
  color: #fff;
  background-color: #08adf7;
}

@media screen and (min-width: 1024px) {
  ul li {
    display: inline;
  }

  .nav-items {
    margin-left: auto;
  }
}

@media screen and (max-width: 1250px) {
  .nav-text {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1250px) {
  .nav-text {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .mobile-padding-nav {
    padding-left: 24px;
    padding-right: 24px;
  }

  .nav-button {
    font-size: 1.3rem;
    padding: 10px 30px;
  }

  .nav-items {
    position: absolute;
    top: -185%;
    transform: translateY(-300%);
    transition: transform 1s ease;

    //position: relative;
    overflow: hidden;
    //background-color: #272727;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    //display: none;
    //padding: 15px;

    //display: flex;
    //align-items: center;
    //justify-content: center;

    z-index: 11;

  }

  ul {
    position: absolute;
    top: -2%;
    background-color: #272727;
    width: 100vw;
    height: 60vh;

    //padding-top: 150px;

    border-radius: 0 0 32px 32px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    -webkit-box-pack: flex-end;

    padding-bottom: 30px;
  }


  ul > :last-child {
    margin-top: 10px;
  }

  .nav-text > a {
    font-size: 1.4rem;
    color: #fff;
  }

  .nav-button {
    background-color: #0da1ff;
  }

  .nav-active {
    transform: translateY(-10%);
    //display: block;
    //top: 200px
  }

  //.nav-items > ul >  li {
  //  margin-top: 3px;
  //  display: block;
  //  width: 100%;
  //}

  .nav-text {
    padding: 8px;
  }

  .nav {
    align-items: center;
    justify-content: center;
    flex-flow: wrap row;
    width: 100%;
    padding: 0 15px;
  }

  .break {
    order: 3;
    width: 100%;
  }

  .lines {
    display: flex;
    gap: 3.5px;
    flex-direction: column;
    flex: 1;
    order: 2;
    cursor: pointer;

    z-index: 12;
  }

  .lines > div {
    width: 35px;
    height: 6px;
    background-color: #000;
    border-radius: 16px;
    margin-left: auto;

    transition: background-color;
  }


  .lines.active > div {
    transition-delay: .5s;
    background-color: #fff;
  }

  .logo {
    flex: 1;
    order: 1;
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    z-index: 12;
  }

  .logo > img {
    height: 32px;
  }

}

@media screen and (max-height: 750px) {
  .nav-text > a {
    font-size: 1.05rem;
  }

  .nav-active {
    transform: translateY(-2%);
  }
}

@media screen and (max-width: 320px) {
  .mobile-padding-nav {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .ml-auto-desktop {
    margin-left: auto;
  }

  .logo { order: 1}
  .nav-items { order: 2}


  .container.is-nav {
    max-width:none !important;
    padding-left:192px;
    padding-right:192px;
    width:100%
  }

  .logo > img {
    height: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .logo {
    //width: 20px;
    order: 1;
    flex: none;
  }

  .lines {
    flex: none;
  }

  .nav-items {
    order: 4;
  }
}

</style>
