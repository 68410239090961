<template>
  <footer class="mb-5">
    <div class="container footer-container" style="position:relative;">
      <div class="footerFlex">
        <img src="/allmake_footer.svg" class="footer-image">
        <div class="footerRight">
          <div class="navigation">
            <h1 class="title is-3 mb-1 footer-title">Nawigacja</h1>
            <ul class="navigation">
              <li><router-link to="/">Strona główna</router-link></li>
              <li><a @click="scrollTo('about-us')">O nas</a></li>
              <li><a @click="scrollTo('trustUs')">Zaufali nam</a></li>
              <li><router-link to="/reklama">Reklama</router-link></li>
              <li><router-link to="/portfolio">Portfolio</router-link></li>
              <li><a @click="scrollTo('contact')">Kontakt</a></li>
            </ul>
          </div>
          <div>
            <h1 class="title is-3 mb-1 footer-title">Social Media</h1>
            <div class="icons-container" style="gap: 10px;">
              <a href="https://www.instagram.com/allmake.pl/" target="_blank" rel="noopener"><font-awesome-icon icon="fa-brands fa-instagram" size="2x" class="icon-color" /></a>
              <a href="https://discord.gg/Ymd78XQW8U" target="_blank" rel="noopener"><font-awesome-icon icon="fa-brands fa-discord" size="2x" class="icon-color" /></a>
              <a href="https://www.tiktok.com/@allmake.pl" target="_blank" rel="noopener"><font-awesome-icon icon="fa-brands fa-tiktok" size="2x" class="icon-color" /></a>
            </div>
          </div>
        </div>
      </div>
      <hr class="footer-line">
      <div class="footer-bottom">
        <h1 class="title is-6 mb-1">© allmake.pl 2022</h1>
        <h1 class="title is-6 ml-auto-desktop">Wszelkie prawa zastrzeżone.</h1>
      </div>
    </div>
  </footer>
</template>

<script>
import {scrollTo} from "@/scrollUtil";

export default {
  name: "Footer",
  methods: {
    scrollTo(id) {
      scrollTo(id, this.$router, this.$route.path);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.navigation {
  margin-left: auto;
  text-align: left;

  @include mobile {
    margin-left: 0;

    text-align: center;
  }
}
.footerFlex {
  display: flex;
  align-items: center;
  position: relative;

  @include mobile {
    flex-direction: column;
  }
}

.footerRight {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-left: auto;
  margin-right: 3vw;

  @include mobile {
    margin: 30px 0;

    flex-direction: column;
    justify-content: center;

    gap: 15px;
  }
}

.navigation > li {
  padding-bottom: 5px;
}

.navigation > li > a {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  transition: color .5s;
}

.navigation > li:hover > a {
  color: #08adf7;
}

.footer-title {
  color: #1a1a1a;
  font-weight: 700;
}

.icon-color {
  color: #1a1a1a;
  cursor: pointer;
  transition: color .5s;
}

.icon-color:hover {
  color: #08adf7;
}

.footer-line {
  border: 1px solid #000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  display: flex;
}


@media screen and (max-width: 700px) {
  .footer-bottom {
    flex-direction: column;
  }
}

.footer-image {
  //margin-top: auto;
  //margin-bottom: 30px;
  ////transform: translateY(30%);
  //////@include until(1200px) {
  //
  //@include until(1200px) {
  //  margin-bottom: 10px;
  //}
}

.footer-image {
  //margin-top: auto;
  //margin-bottom: 3.5%;

  @include desktop {
    max-width: 25%;
    margin-left: 8vw;
  }

  @include until(1250px) {
    max-width: 30%;
  }

  @include touch {
    margin-left: 2.5vw;
    max-width: 30%;
    margin-bottom: 5%;
  }

  @include mobile {
    margin-top: 0;

    max-width: 60%;
  }

  @include until(450px) {
    max-width: 90%;
  }

  //@include from(1200px) {
  //  max-width: 120%;
  //}

  //@include until(700px) {
  //  margin-top: auto;
  //}
}


.footer-container {
  @include touch {
    margin-left: 32px;
    margin-right: 32px;
  }
}

.ml-auto-desktop {
  margin-left: auto;

  @include until(700px) {
    margin-left: 0;
  }
}
</style>
