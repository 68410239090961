<template>
  <div style="max-width: 2650px; margin-left: auto; margin-right: auto">
    <YoutubeVideo :active="ytVideoActive" @active="updateActive"></YoutubeVideo>
    <div :class="[{'blurBg': true}, {'active': blurActive}, {'activeVideo': ytVideoActive}]"></div>
    <div class="hero is-fullheight" style="position: relative;">
      <div class="hero-head absolute">
        <Navbar @blur="blur"></Navbar>
      </div>
      <div class="hero-body">
        <div class="container is-fluid-hero">
          <div class="columns is-vcentered">
            <div class="column is-8-widescreen is-12-desktop is-12-touch text-hero">
              <h1 class="title has-text-weight-semibold title1">Tak, zaspokoimy twoje oczekiwania.</h1>
              <h1 class="subtitle primary has-text-weight-bold subtitle1 mb-4">Aż tak bardzo widać?</h1>
              <div class="aboutHero">
                <a class="button-dark" @click="ytVideoActive = true">
                  <img src="/images/oko.svg">
                  Coś o nas
                </a>
<!--                <div class="breakk"></div>-->
                <a href="https://www.tiktok.com/@allmake.pl" class="is-hidden-mobile" target="_blank"><font-awesome-icon icon="fa-brands fa-tiktok" size="2x"  class="heroIcon" /></a>
                <a href="https://www.instagram.com/allmake.pl/" class="is-hidden-mobile" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="2x"  class="heroIcon" /></a>
                <a href="https://discord.gg/Ymd78XQW8U" class="is-hidden-mobile" target="_blank"><font-awesome-icon icon="fa-brands fa-discord" size="2x"  class="heroIcon" /></a>
              </div>
            </div>
            <div class="column is-4 is-flex is-justify-content-center znikaj">
              <img src="/images/misiu.svg" class="misiu"/>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot" style="animation: bounce 2s infinite;">
        <a @click="scrollTo('about-us', $router, $route.path)" class="scrollIcon">
          <font-awesome-icon icon="fa-solid fa-angle-down" size="3x" />
        </a>
      </div>
      <div class="heroImages disable-scrollbar">
        <img src="/images/decoration/hero/blob1.svg" class="blob1">
        <img src="/images/decoration/hero/blob2.svg" class="blob2">
        <img src="/images/decoration/hero/blob3.svg" class="blob3">
        <img src="/images/decoration/hero/triangles.svg" class="triangles">
        <img src="/images/decoration/hero/star1.svg" class="gStar1" >
        <img src="/images/decoration/hero/star1.svg" class="gStar2">
        <img src="/images/decoration/hero/gwiazda.svg" class="star1">
        <img src="/images/decoration/hero/gwiazdaMala.svg" class="star2">
      </div>
    </div>
    <img src="/images/seperator.svg" class="seperator" />

    <!-- REST OF WEBSITE, CONTAINER TO ADD IMAGES -->
    <div style="position: relative; margin-top: -200px; overflow: hidden">
      <div style="position: relative; overflow:hidden; max-width: 100%">
        <div style="overflow:hidden">
          <img src="/images/decoration/aboutUs/bigWave.svg" class="bigWave">
          <img src="/images/decoration/aboutUs/bigWave2.svg" class="bigWave2">
          <img src="/images/decoration/aboutUs/dots.svg" class="dots">
          <img src="/images/decoration/aboutUs/triangle.svg" class="triangle">
          <img src="/images/decoration/aboutUs/star.svg" class="starr">
          <img src="/images/decoration/aboutUs/star.svg" class="starr2">
          <img src="/images/decoration/aboutUs/circle.svg" class="circle">
          <img src="/images/decoration/aboutUs/triangles.svg" class="triangless">
          <img src="/images/decoration/aboutUs/darkStar.svg" class="darkStar">
          <img src="/images/decoration/aboutUs/darkStar.svg" class="darkSmallStar">
          <img src="/images/decoration/aboutUs/bigStar.svg" class="bigStar">
        </div>
        <div>
          <div class="about-us" id="about-us" data-aos="fade-up" style="padding-top: 18rem;">
            <div class="container has-text-centered is-fluid" style="margin-bottom: 3rem;">
              <h1 class="title sectionTitle primary mb2">Czym się zajmujemy?</h1>
            </div>
            <Eye @blur="blur"></Eye>
          </div>
          <TrustUsSection :companies="companies" data-aos="fade-up"></TrustUsSection>
          <ContactSection style="margin-bottom: 8rem;" ></ContactSection>
<!--          <div class="section" id="trustUs" style="margin-bottom: 8rem;">-->
<!--            <div class="container is-max-desktop">-->
<!--              dsadanlkjsdsabhjdsa-->
<!--            </div>-->
<!--          </div>-->
<!--          <ContactSection style="margin-bottom: 8rem;"></ContactSection>-->
          <Footer></Footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// import Carousel from "@/components/Carousel";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import Eye from "@/components/Eye";
import ContactSection from "@/components/ContactSection";
import TrustUsSection from "@/components/TrustUsSection";
import {checkForHashTag} from "@/scrollUtil";
import YoutubeVideo from "@/components/YoutubeVideo";
import {scrollTo} from "@/scrollUtil";

export default {
  name: 'Home',
  components: {
    YoutubeVideo,
    // eslint-disable-next-line vue/no-unused-components
    TrustUsSection, ContactSection, Navbar, Footer,
    // Carousel,
    Eye
  },
  // components: { VueperSlides, VueperSlide },
  data() {
    return {
      companies: [],
      blurActive: false,
      ytVideoActive: false
      // {icon: "fa-solid fa-rectangle-ad", title: "Tworzeniem reklam", subtitle: "Stworzymy dla ciebie reklamę twojego projektu!"},
      // {icon: "fa-solid fa-microphone-lines", title: "Lektorat", subtitle: "Nagramy świetny lektorat pod twój film!"},
      // {icon: "fa-solid fa-clapperboard", title: "Montażem Wideo", subtitle: "Zmontujemy film wedle twoich oczekiwań!"},
      // {icon: "fa-brands fa-discord", title: "BOTami Discord", subtitle: "Napiszemy dla ciebie bota  przeznaczonego konkretnie pod twój serwer!"},
      // {icon: "fa-solid fa-palette", title: "Tworzeniem Grafiki Cyfrowej", subtitle: "Stworzymy szatę graficzną dla twojego projektu!"},
      // {icon: "fa-solid fa-globe", title: "Projektowaniem Stron WWW", subtitle: "Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!"},
    }
  }, created() {
    const companies = process.env.VUE_APP_COMPANIES;

    this.companies = JSON.parse(companies);
  }, methods: {
    blur(value) {
      this.blurActive = value;
    },
    updateActive(value) {
      this.ytVideoActive = value;
    }, scrollTo
  }, mounted() {
    checkForHashTag();
  }
}
</script>
