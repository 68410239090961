<template>
  <div>
    <h1 class="title is-2 sectionTitle has-text-weight-bold mb-0">Nasze portfolio</h1>
    <div class="carouselContainer">
      <div class="carouselBox">
        <div class="controls">
        <span class="iconLeft" @click="moveLeft(true)">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </span>
          <span class="iconRight" @click="moveRight(true)">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </span>
        </div>
        <div class="carouselPosition">
          <div class="carousel" id="carousel2" style="transform: translate3d(0px, 0px, 0px);">
            <div :class="['carCard', {'clickable': website.url}]" v-for="(website, id) in websites" :key="website" :id="'card' + id" :style="'order: ' + id" @click="open(website.url)">
              <div class="info">
                <span>{{ website.type }}</span>
              </div>
              <div class="imagesContainer">
                <img :src="website.bgImg" class="bgImg">
                <img :class="['overImage', {'overImageOpacity': website.bgImg}]" :src="website.img">
              </div>
              <div class="cardDescrption">
                <span>{{ website.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselPortfolio",
  props: {
    websites: Array
  }, methods: {
    getMatrix(element) {
      const values = element.style.transform.split(/\w+\(|\);?/);
      const transform = values[1].split(/,\s?/g).map(parseInt);

      return {
        x: transform[0],
        y: transform[1],
        z: transform[2]
      }
    }, getEl() {
      if(window.screen.width < 1023) return this.websites.length-1;
      if(window.screen.width < 1215) return (this.websites.length-2);
      return this.websites.length-3;
    }, backlight() {
      const newPos = this.position-1;

      document.getElementById("card" + newPos).classList.add("active");
    }, changeOrderLeft() {
      const vue = this;

      this.elements.forEach(el => {
        if(parseInt(el.style.order) === vue.websites.length-1) el.style.order = 0;
        else el.style.order = parseInt(el.style.order)+1;
      });
    }, changeOrderRight() {
      const vue = this;

      this.elements.forEach(el => {
        if(parseInt(el.style.order) === 0) el.style.order = vue.websites.length-1;
        else el.style.order = parseInt(el.style.order)-1;
      });
    }, moveLeft(user) {
      if(user && !this.userInteracted) this.userInteracted = true;
      if(this.animation) return;

      this.animation = true;

      const element = document.getElementById("carousel2");

      element.classList.remove("disableAnimation")

      element.style.transform = "translate3d(" + (this.getSize()*2) + "px, 0px, 0px)";

      const vue = this;
      setTimeout(function() {
        element.classList.add("disableAnimation")

        vue.changeOrderLeft();

        element.style.transform = "translate3d(" + vue.getSize() + "px, 0px, 0px)";

        vue.highlight();

        vue.animation = false;
      }, 500);
    }, moveRight(user) {
      if(user && !this.userInteracted) this.userInteracted = true;

      if(this.animation) return;

      this.animation = true;

      const element = document.getElementById("carousel2");

      element.classList.remove("disableAnimation")

      element.style.transform = "translate3d(0px, 0px, 0px)";

      const vue = this;
      setTimeout(function() {
        element.classList.add("disableAnimation")

        vue.changeOrderRight();

        element.style.transform = "translate3d(" + vue.getSize() + "px, 0px, 0px)";

        vue.highlight();

        vue.animation = false;
      }, 500);
    }, createMoveInterval() {
      this.userInteracted = false;

      const vue = this;
      this.moveInterval = setInterval(function() { vue.animateRight(false) }, 5000);
    }, highlight() {
      if(this.highlightEl) this.highlightEl.classList.remove("active");

      const vue = this;
      this.elements.forEach(el => {
        if(parseInt(el.style.order) === (window.innerWidth < 950 ? vue.center+1 : vue.center)) {
          vue.highlightEl = el;
          el.classList.add("active")
        }
      })
    }, getSize() {
      if(window.innerWidth < 500) return 250;
      return 350;
    }, open(url) {
      if(url) window.open(url)
    }
  }, mounted() {
    this.position = this.websites.length;

    // eslint-disable-next-line for-direction
    for (let x = 0; x < this.websites.length; x++) {
      this.elements.push(document.getElementById("card" + x));
    }

    this.center = this.websites.length-3;

    this.highlight();

    this.createMoveInterval();

    const element = document.getElementById("carousel2");

    element.style.transform = "translate3d(" + (this.getSize()) + "px, 0px, 0px)";
  }, data() {
    return {
      position: 0,
      elements: [],
      animation: false,
      userInteracted: false,
      highlightEl: undefined,
      mediana: undefined
    }
  }, watch: {
    userInteracted() {
      clearInterval(this.moveInterval);

      const vue = this;
      setTimeout(() => vue.createMoveInterval(), 5000)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.carouselContainer {
  min-width: 1200px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @include until(1200px) {
    min-width: 800px;
    max-width: 800px;
  }

  @include until(950px) {
    min-width: 500px;
    max-width: 500px;
  }

  @include until(500px) {
    min-width: 95vw;
    max-width: 95vw;
  }
}

.carCard {
  position: relative;

  min-width: 300px;
  max-width: 300px;

  margin-top: 50px;
  margin-bottom: 50px;

  box-shadow: 0px 11px 28px -20px rgba(0, 0, 0, 1);

  border-radius: 16px;

  transition: transform .5s;

  @include until(500px) {
    min-width: 200px;
    max-width: 200px;
  }

  &.clickable {
    cursor: pointer;
  }

  .imagesContainer {
    position: relative;
    width: 100%;
    min-height: 150px;
    height: 150px;
    max-height: 150px;

    img {
      border-radius: 16px 16px 0 0;
    }

    .overImage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;

      transition: opacity .5s;
    }

    .bgImg {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: 0 0;

      //transition: object-position 2s .5s linear;
      transition: object-position .1s .5s linear;
      //transition-delay: .5s;
    }
  }

  &.active {
    .overImageOpacity {
      opacity: 0;
    }

    .bgImg {
      transition: object-position 2s .5s ease-in-out;
      //transition: object-position .1s linear;
      object-position: 0 100%;
    }
  }
}

.carCard > .info {
  transition: opacity .5s;

  position: absolute;

  display: flex;
  justify-content: center;

  top: -16px;
  left:0;
  right: 0;

  opacity: 0;
  z-index: 5;
}

.info > span {
  padding: 5px 20px;
  background-color: #0b9bf4;
  border-radius: 16px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0px 3px 0px rgba(0,0,0,0.21);
}

.carCard.active > .info {
  opacity: 1;
}


.carCard > .cardDescrption > span {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.carCard.active > .cardDescrption > span {
  color: #fff
}

.carCard > .cardDescrption {
  padding-top: 3px;
  padding-bottom: 3px;

  background-color: #fff;

  border-radius: 0 0 16px 16px;
}

.carCard.active {
  transform: translate3D(0,0,0) scale(1.1);
}

.carCard.active > .cardDescrption {
  background-color: #0b9bf4;
}

.cardDescrption > span {
  font-weight: 600;
  font-size: 1.25rem;
}

.carouselBox {
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.carouselPosition {
  min-width: 1050px;
  max-width: 1050px;

  margin-left: auto;
  margin-right: auto;

  overflow-x: auto;

  @include until(1200px) {
    min-width: 720px;
    max-width: 720px;
  }

  @include until(950px) {
    min-width: 350px;
    max-width: 350px;
  }

  @include until(500px) {
    min-width: 250px;
    max-width: 250px;
  }
}

.carouselPosition::-webkit-scrollbar {
  display: none;
}


.carousel {
  /*overflow-x: hidden;*/
  display: flex;
  flex-wrap: nowrap;
  gap: 50px;
  align-items: center;
  justify-content: right;
  -webkit-justify-content: flex-end;
  transition: transform .3s;
  margin-right: 25px;
  margin-left: 25px;

  //@include until(500px) {
  //  gap: 59px;
  //}
}

.controls {
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.disableAnimation {
  transition: none !important;
}
</style>
