<template>
  <div class="section trustUs" id="trustUs">
    <div class="container">
      <h1 class="title is-2 primary">Zaufali nam</h1>
      <div class="is-flex is-justify-content-center">
        <Carousel :companies="companies"></Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";

export default {
  name: "TrustUsSection",
  props: {
    companies: Array
  }, components: {
    Carousel
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.trustUs {
  margin-top: 1rem;
  margin-bottom: 12rem;

  @include mobile {
    margin-bottom: 5rem;
  }
}
</style>
