<template>
  <div>
<!--    <div :class="['frame', {'show' : active}]"></div>-->
    <div :class="['videoContainer', {'show' : active}]" @click="changeActive(false)">
      <iframe id='trailer' src="https://www.youtube-nocookie.com/embed/1ysfA_b_B58?enablejsapi=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="loaded"></iframe>
<!--      <video controls id="video" class="video" preload="none">-->
<!--        <source src="/trailer.mp4" type="video/mp4">-->
<!--        Your browser does not support HTML video.-->
<!--      </video>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "YoutubeVideo",
  props: {
    active: Boolean
  }, data() {
    return {
      loaded: false
    }
  }, watch: {
    active(value) {
      if(!this.loaded && value) {
      //   document.getElementById("video").load();
        this.loaded = true;
      }

      if(!value) {
        document.getElementById("trailer").contentWindow
            .postMessage('{"event":"command","func":"stopVideo","args":""}', '*')

      }

      this.$emit('active', value);
    }
  }, methods: {
    changeActive(value) {
      this.$emit('active', value);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

.frame {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: -5;

  backdrop-filter: blur(5px) opacity(0);
  //opacity: 0;

  transition: backdrop-filter .5s;
}

.frame.show {
  //backdrop-filter: blur(8px) opacity(1);

  backdrop-filter: blur(5px) opacity(1);

  z-index: 6;
}

//.frame > video {
//  opacity: 0;
//
//  transition: opacity .5s;
//}
//
//.frame.show > video {
//  opacity: 1;
//}

.videoContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: -7;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity .5s;
}

.videoContainer.show {
  z-index: 7;

  opacity: 1;
}

iframe {
  border-radius: 32px;

  width: calc(1920px/2.5);
  height: calc(1080px/2.5);

  @include touch() {
    width: calc(1920px/3.5);
    height: calc(1080px/3.5);
  }

  @include until(600px) {
    width: 380px;
    height: 220px;
  }

  @include until(400px) {
    width: 340px;
    height: 210px;
  }
}
</style>
