<template>
  <div style="position: relative; overflow-y: hidden; overflow-x: hidden; z-index: 0">
    <div :class="[{'blurBg': true}, {'active': blurActive}]"></div>
    <div>
      <img src="/images/decoration/portfolio/bigWavee.svg" class="bigWavee">
      <img src="/images/decoration/hero/triangles.svg" class="triangles">
      <img src="/images/decoration/portfolio/wave2.svg" class="wave2">
      <img src="/images/decoration/portfolio/bigBlueStar.svg" class="bigBlueStar">
      <img src="/images/decoration/portfolio/smallBlueStar.svg" class="smallBlueStar">
      <img src="/images/decoration/portfolio/bigWhiteStar.svg" class="bigWhiteStar">
      <img src="/images/decoration/portfolio/smallWhiteStar.svg" class="smallWhiteStar">

      <img src="/images/decoration/portfolio/veryBigWhiteStar.svg" class="veryBigWhiteStar">
      <img src="/images/decoration/portfolio/smallDarkStar.svg" class="smallDarkStar">

      <img src="/images/decoration/aboutUs/triangles.svg" class="triangles2">
      <img src="/images/decoration/aboutUs/circle.svg" class="circle">

      <img src="/images/decoration/portfolio/smallDarkStar.svg" class="smallDarkStar2">
    </div>
    <Navbar @blur="blur"></Navbar>
    <div data-aos="fade-up">
      <div class="container has-text-centered" style="margin-top: 12rem">
        <h1 class="headText">Zestaw Afilianta</h1>
        <h2 class="descText mb-5">(Zawiera reklamę tiktok, banner na profil Discord)</h2>
        <a class="btn" href="/allmake_pack.rar" download>Pobierz</a>
      </div>
    </div>
    <ContactSection style="margin-bottom: 8rem; margin-top: 14rem"></ContactSection>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import ContactSection from "@/components/ContactSection";
import Footer from "@/components/Footer";

export default {
  name: "Reklama",
  data() {
    return {
      blurActive: false
    }
  },
  components: { Footer, ContactSection, Navbar },
  methods: {
    blur(value) {
      this.blurActive = value;
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";
.bigWavee {
  position: absolute;
  z-index: -5;
  top: -500px;
  right: -500px;
  transform: scale(0.8);

  @include until(1200px) {
    top: -120px;
    right: -500px;
  }

  @include touch {
    transform: scale(2.5);
    top: 200px;
    right: -5--0px;
  }
}

.triangles {
  position: absolute;
  z-index: -5;
  top: -50px;
  left: 75px;
  transform: scale(0.8)
}

.wave2 {
  position: absolute;
  z-index: -5;
  bottom: -300px;
  left: -50px;
  transform: scale(1)
}

.bigBlueStar {
  position: absolute;
  z-index: -4;
  top: 300px;
  right: 300px;
  transform: scale(1);

  @include touch {
    display: none;
  }
}

.smallBlueStar {
  position: absolute;
  z-index: -4;
  top: 250px;
  right: 200px;
  transform: scale(1);

  @include touch {
    display: none;
  }
}

.bigWhiteStar {
  position: absolute;
  z-index: -4;
  top: 325px;
  left: 250px;
  transform: scale(1);

  @include touch {
    display: none;
  }
}

.smallWhiteStar {
  position: absolute;
  z-index: -4;
  top: 250px;
  left: 200px;
  transform: scale(1);

  @include touch {
    display: none;
  }
}

.veryBigWhiteStar {
  position: absolute;
  z-index: -4;
  bottom: 225px;
  left: 175px;
  transform: scale(0.8);

  @include touch {
    display: none;
  }
}

.smallDarkStar {
  position: absolute;
  z-index: -4;
  bottom: 200px;
  left: 150px;
  transform: scale(0.8);

  @include touch {
    display: none;
  }
}

.triangles2 {
  position: absolute;
  z-index: -4;
  bottom: 400px;
  right: -475px;
  transform: scale(0.5)
}

.circle {
  position: absolute;
  z-index: -4;
  bottom: 400px;
  left: -550px;
  transform: scale(0.6);
  filter: blur(5px);
}

.smallDarkStar2 {
  position: absolute;
  z-index: -4;
  bottom: 400px;
  right: 150px;
  transform: scale(0.6);

  @include touch {
    display: none;
  }
}

.headText {
  font-size: clamp(2rem, 8vw, 3rem);
  color: #000000;
  font-weight: 700;
}

.descText {
  color: #000000;
  font-weight: 600;
  /* font-size: 1.25rem; */
  /* font-size: 1rem; */
  /* font-size: 5vw; */
  font-size: clamp(1rem, 4vw, 1.25rem);
}

.btn {
  background-color: #0b9bf4;
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  padding: 4px 32px;
  border-radius: 16px;
}
</style>
