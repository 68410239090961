import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

import "@/assets/css/styles.scss"
import "@/assets/css/decoration.scss"

Vue.use(VueMeta)
Vue.config.productionTip = false

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faMicrophoneLines,
  faRectangleAd,
  faPalette,
  faGlobe,
  faAngleLeft,
  faAngleRight,
  faEnvelope,
  faClapperboard,
  faAngleDown,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutube, faDiscord, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleLeft, faXmark, faAngleRight, faFacebook, faYoutube, faEnvelope, faDiscord, faTiktok, faInstagram, faClapperboard, faClapperboard, faRectangleAd, faGlobe, faMicrophoneLines, faPalette, faAngleDown);
Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'aos/dist/aos.css';
import AOS from "aos";

new Vue({
  router,
  render: h => h(App),
  mounted() {
    AOS.init({
      startEvent: 'load',
    });
  }
}).$mount('#app')
